@import url('https://fonts.googleapis.com/css2?family=Nunito:wght@400;600;700&family=Ubuntu:wght@400;700&display=swap');

body {
  background-color: #ededed;
}

input, button, select {
  &:focus {
    outline: none !important;
  }
}

::-webkit-scrollbar {
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px #E9E9E9;
  border-radius: 5px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background-color: #1B3142;
  border-radius: 5px;
}

header {
  ::placeholder {
    font-size: 15px !important;
    font-weight: 600 !important;
    color: rgb(145, 155, 163) !important;
  }
}

.responsive-placeholder::placeholder {
  font-size: 16px !important;
  @media(max-width: 1024px) {
   font-size: 12px !important;
  }
}

.lgPlaceholder::placeholder {
  font-size: 22px !important;
}

.radialGradient {
  background: linear-gradient(180deg, #EB6B5B 0%, #ECBAF6 100%);
  filter: blur(70px);
  border-radius: 50%;
  width: 100px;
  height: 100px;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -5;
  &--md {
    width: 100px;
    height: 100px;
  }
  &--lg {
    width: 300px;
    height: 300px;
  }
}

.bgShadow {
  background: linear-gradient(180deg, #EB6B5B 0%, #ECBAF6 100%);
  filter: blur(120px);
  border-radius: 50%;
  width: 100%;
  height: 100px;
  position: absolute;
  top: 200px;
}

.blueBgShadow {
  background: linear-gradient(180deg, #1B3142 0%, #1B3142 100%);
  filter: blur(100px);
  border-radius: 50%;
  width: 90%;
  height: 50px;
  position: absolute;
  top: 100px;
}

.linearGradient {
  background: linear-gradient(to right, #EB6B5B 0, #ECBAF6 90%);
}

.textGradient {
  background: -webkit-linear-gradient(#EB6B5B, #ECBAF6);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.linearGradientTopToBottom {
  background: linear-gradient(to bottom, #EB6B5B 0, #ECBAF6 90%);
}

.lightShadow {
  box-shadow: 30px 70px 120px rgba(27, 49, 66, 0.13);
}

.gradient-box {
  display: flex;
  align-items: center;
  //width: 50vw;
  width: 90%;
  max-width: 128px;

  position: relative;
  padding: 8px;
  box-sizing: border-box;

  $border: 1px;
  color: #FFF;
  background: #fff;
  background-clip: padding-box; /* !importanté */
  border: solid $border transparent; /* !importanté */
  border-radius: 12px;

  &:before {
    content: '';
    position: absolute;
    top: 0; right: 0; bottom: 0; left: 0;
    z-index: -1;
    margin: -$border; /* !important */
    border-radius: inherit; /* !important */
    background: linear-gradient(to right, #EB6B5B, #ECBAF6);
  }
}

.colorful-checkbox {
  label > input[type="checkbox"] {
    display: none;
  }
  label > input[type="checkbox"] + *::before {
    content: "";
    display: inline-block;
    vertical-align: bottom;
    width: 23px;
    height: 23px;
    border-radius: 10%;
    border-style: solid;
    border-width: 0.1rem;
    border-color: #ECBAF6;
    margin-right: 9px;
    cursor: pointer;
  }
  label > input[type="checkbox"]:checked + *::before {
    content: "✓";
    color: #ECBAF6;
    text-align: center;
    border-color: #ECBAF6;
    margin-right: 9px;
    //margin-bottom: 3px;
  }
  label > input[type="checkbox"]:checked + * {
    color: #1B3142;
  }
}

.custom-file-input::-webkit-file-upload-button {
  visibility: hidden;
}

.live-auction-lg {
  @media (min-width: 1024px) {
    @media (max-width: 1027px) {
      padding-left: 5px !important;
      padding-right: 25px !important;
    }
  }
}

.grid-cols-4-2xl {
  @media (min-width: 1800px) {
    grid-template-columns: repeat(4, minmax(0, 1fr)) !important;
  }
}


