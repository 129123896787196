/* custom drawer */
.drawer-handle {
  display: none !important;
}

.drawer-content-wrapper {
  border-radius: 40px;
}

/* custom slider */
.slick-track {
  display: flex !important;
}

.slick-slides-gap {
  &--lg {
    .slick-list {margin: 0 -16px;}
    .slick-slide>div {padding: 0 16px;}
    @media (min-width: 768px) {
      .slick-list {margin: 0 -24px;}
      .slick-slide>div {padding: 0 24px;}
    }
  }
}

.c-slick-dots {
  &-bottom {
    &--55 {
      .slick-dots {
        bottom: -55px;
      }
    }
    &--40 {
      .slick-dots {
        bottom: -40px;
      }
    }
  }

  .slick-dots {
    & > li button:before{
      font-size: 12px;
      line-height: 10px;
      width: 10px;
      height: 10px;
      content: '•';
      opacity: 1;
      color: transparent;
      border: 1px solid #fff;
      border-radius: 50%;
    }
    & > li.slick-active button:before {
      color: #fff !important;
      opacity: 1;
      border: 0;
      box-shadow: 0 0 20px 10px rgba(255, 255, 255, 0.7);
    }
  }
}

.main-sidebar{
  @media (min-width: 1024px) {
    max-width: 185px;
    min-width: 185px;
  }
}
.slider-card{
  width: calc( (100vw - ( 185px + 44px + 16px + (24px * (4 * 2 - 2) ) )  ) / 4 );
  @media (max-width: 1799px) {
    width: calc( (100vw - ( 185px + 44px + 16px + (24px * (3 * 2 - 2) ) )  ) / 3 );
  }
  @media (max-width: 1279px) {
    width: calc( (100vw - ( 185px + 44px + 16px + (24px * (2 * 2 - 2) ) )  ) / 2 );
  }
  @media (max-width: 1023px) {
    width: calc( (100vw - ( 20px + 20px + (16px * (2 * 2 - 2) ) )  ) / 2 );
  }
  @media (max-width: 639px) {
    width: calc( (100vw - ( 20px + 20px )  ) / 1 );
  }
}


.empty-icon {
  @media (max-width: 767px) {
    width: 110px !important;
    height: 110px !important;
    margin-right: 1.25rem;
  }
}

.main-header {
  z-index: 30;
  @media (min-width: 1024px) {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    height: 124px;
    border-radius: 0 0 50px 50px;
    background-color: rgb(232, 233, 236);

    @supports ((-webkit-backdrop-filter: blur(104px)) or (backdrop-filter: blur(104px))) {
      background-color: rgba(232, 233, 236, 0.1);
      backdrop-filter: blur(104px);
      -webkit-backdrop-filter: blur(104px);
    }
  }
}

.main-content {
  @media (min-width: 1024px) {
    padding-top: 124px;

    &.have-sidebar {
      padding-left: 185px;
    }
  }
}

.main-sidebar {
  @media (min-width: 1024px) {
    padding-top: 124px;
    position: fixed;
    top: 124px;
    left: 0;
    width: 185px;
  }
}

.primary-box {
  background: linear-gradient(to right, rgba(235, 107, 91, 1) 0%, rgba(236, 186, 246, 1) 100%);
  border: 1px solid rgba(255, 255, 255, 1);
  padding: 15px 32px;
  color: #1C3142;
  border-radius: 2rem;

  @supports ((-webkit-backdrop-filter: blur(200px)) or (backdrop-filter: blur(200px))) {
    background: #FFFFFF;
    border: 1px solid rgba(255, 255, 255, 0.4);
    backdrop-filter: blur(200px);
    -webkit-backdrop-filter: blur(200px);
  }
}